import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (

    <Link className="post-link" to={post.frontmatter.slug}>
      <h2>{post.frontmatter.title}</h2>
      <p>{post.excerpt}</p>
      <time className="pubdate" datetime={post.frontmatter.rawDate}>Published {post.frontmatter.date}</time>
    </Link>

)
export default PostLink
