import React from "react"
import { graphql } from "gatsby"
import PostLink from "../../components/post-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/main.scss"

const BlogPosts = ({ posts }) => {
  return (
    <Layout className="blog">
      <SEO title="Blog" />

      <section className="posts">
        {posts.map(post => (
          <PostLink key={post.id} post={post} />
        ))}
      </section>
    </Layout>
  )
}

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => edge.node)
  return <BlogPosts posts={posts} />
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 450)
          frontmatter {
            date(formatString: "D MMMM, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`

export default IndexPage
